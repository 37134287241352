import React from "react";
import classes from "./Filter.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader, Dimmer } from "semantic-ui-react";
import blackCross from "../../images/crossBlack.svg";

const infiniteScrollStyle = {
  overflowX: "hidden",
  height: "60vh",
};

export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filtersResponse,
      selectedFilters: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filtersResponse.length !== this.props.filtersResponse.length
    ) {
      this.setState({
        filters: this.props.filtersResponse,
      });
    }
  }

  render() {
    let { filters } = this.state;
    return (
      <div className={classes.FilterContainer}>
        <div className={classes.FilterBody}>
          <div className={classes.FilterHeader}>
            <div className={classes.HeaderText}>
              <img
                src={blackCross}
                alt="Black Cross"
                style={{ width: "14px", marginRight: "20px", height: "14px" }}
                onClick={this.props.filterClicked}
              />
              Filters
            </div>

            <div
              className={classes.HeaderAction}
              onClick={() => {
                this.props.handleClearAll();
              }}
            >
              Clear
            </div>
          </div>
          <div className={classes.FilterContent}>
            <div className={classes.FilterContentHeading}>
              CATEGORIES / Sub-CATEGORIES
            </div>
            <div className={classes.FilterList}>
              <InfiniteScroll
                next={() => this.props.fetchTags()}
                hasMore={!this.props.filterNoMore}
                height="100%"
                style={infiniteScrollStyle}
                loader={
                  <div className={classes.centeredDiv}>
                    <Dimmer active inverted>
                      <Loader inverted content="Loading..." />
                    </Dimmer>
                  </div>
                }
                dataLength={filters.length}
              >
                {filters.length
                  ? filters.map((fil, i) => {
                      return (
                        <label key={i} className={classes.FilterListLabel}>
                          <p className={classes.FilterListText}>{fil.name}</p>
                          <input
                            type="checkbox"
                            checked={fil.isSelected}
                            onChange={(e) => {
                              this.props.handleSelect(fil);
                            }}
                          />
                        </label>
                      );
                    })
                  : ""}
              </InfiniteScroll>
            </div>
          </div>
          <div className={classes.FilterFooter}>
            <div
              className={classes.HeaderActionFooter}
              onClick={() => {
                this.props.handleApplyClick();
              }}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    );
  }
}
