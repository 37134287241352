import React from "react";
import classes from "./ImagePreviewCard.module.css";
import tag_icon from "../../images/tag.png";
import SVGCross from "../../images/crossModal.svg";

export default function ImagePreviewCard({
  imgData,
  toggleImageSelection,
  handleDownloadClick,
  handleSelectImage,
  togglePreviewImage,
  deselectImageSelection,
}) {
  let { imageUrl, title, tags } = imgData;

  const getCategories = (tags) => {
    let tagString = "";
    if (tags) {
      tags.forEach((tag, index) => {
        tagString = tagString + tag.name;
        if (index < tags.length - 1) {
          tagString = tagString + ", ";
        }
      });
    }

    return tagString;
  };

  const closePreviewModal = () => {
    deselectImageSelection();
    togglePreviewImage();
  };

  return (
    <div className={classes.previewImageContainer}>
      <img
        className={classes.previewModalClose}
        src={SVGCross}
        onClick={closePreviewModal}
        alt="CloseImage"
      />
      <img
        style={{ height: "175px", width: "100%" }}
        src={imageUrl}
        alt="PreviewImage"
      />
      <div className={classes.previewImageTitle}>{title}</div>
      <div className={classes.previewCategoriesContainer}>
        <img
          src={tag_icon}
          className={classes.previewCategoryImage}
          alt="categoryImage"
        />
        <div className={classes.previewCategories}>
          <div className={classes.previewCategoriesHeading}>Categories:</div>
          <div className={classes.previewCategoriesList}>
            {getCategories(tags)}
          </div>
        </div>
      </div>

      <div className={classes.previewButtonContainer}>
        <button
          className={classes.DownloadButton}
          onClick={() => {
            if (!Object.keys(imgData).length) {
              return;
            }
            handleDownloadClick();
          }}
        >
          Download for 100 coins
        </button>
        <button
          className={classes.SelectButton}
          onClick={() => {
            handleSelectImage();
          }}
        >
          Select Image
        </button>
      </div>
    </div>
  );
}
