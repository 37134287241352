import React from "react";
import ImageList from "./components/List/List";
import back_icon from "./images/backicon.svg";
import classes from "./components/List/List.module.css";
import "./App.css";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackButton: true,
      modalHeaderText: "Select Image from Library",
      showPreview: false,

      token: "",
    };
  }

  componentDidMount() {
    let xAccessToken = this.getQuery()["token"];
    if (xAccessToken) {
      this.setState(
        {
          token: xAccessToken,
        },
        () => {
          console.log(this.state.token, "StateToken");
        }
      );
    }
    console.log(xAccessToken, "xAccessToken");
  }

  clickBackButton = () => {
    console.log("Back button called");
  };

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});

    return b;
  };

  render() {
    let { modalHeaderText, showPreview, token } = this.state;
    return (
      <div className={classes.mobileViewContainer}>
        {
          //  <div className={classes.headerContainer}>
          //   {modalHeaderText}
          //   <img
          //     src={back_icon}
          //     alt="CloseIconSvg"
          //     className={classes.headerBackIcon}
          //     onClick={() => {
          //       this.clickBackButton();
          //     }}
          //   />
          // </div>
        }

        <ImageList
          showPreview={showPreview}
          clickPreview={this.clickPreview}
          token={token}
        />
      </div>
    );
  }
}
