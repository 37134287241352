import React, { Component } from "react";
import classes from "./Search.module.css";
import search from "../../images/search.svg";

class Search extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { searchTerm, handleSearch } = this.props;
    return (
      <div className={classes.Search}>
        <img src={search} alt="" className={classes.SearchImage} />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          className={classes.SearchInput}
          placeholder="Search for image"
        />
      </div>
    );
  }
}
export default Search;
