import React, { Component } from "react";
import classes from "./List.module.css";
import Search from "../Search/Search";
import ImageCard from "../ImageCard/ImageCard";
import ImagePreviewCard from "../ImagePreviewCard/ImagePreviewCard";
import filter from "../../images/mobileFilter.svg";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader, Dimmer } from "semantic-ui-react";
import Filter from "../Filter/Filter";
// import { setNotificationImage } from "../../../containers/Notification/actions";

const infiniteScrollStyle = {
  overflowX: "hidden",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
  gridGap: "16px",
};

const infinteScrollEmptyState = {
  overflowX: "hidden",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  width: "100%",
  height: "300px",
};

class List extends Component {
  constructor() {
    super();
    this.state = {
      isFilter: false,
      images: [],
      selectedImage: {},
      limit: 10,
      offset: 0,
      totalCount: 0,
      noMore: false,
      searchTerm: "",
      searching: false,
      loading: false,
      filtersResponse: [],
      filterLimit: 10,
      filterOffset: 0,
      filterLoading: false,
      filterTotalCount: 0,
      selectedFilters: [],
      filterNoMore: false,
      folders: [],
      showPreview: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props && this.props.token != prevProps.token) {
      this.fetchImages();
      this.fetchTags();
    }
  }

  componentDidMount() {
    // this.fetchImages();
    // this.fetchTags();
  }

  filterClicked = () => {
    this.setState({ isFilter: !this.state.isFilter });
  };

  fetchImages = () => {
    this.setState({
      loading: true,
    });

    let { limit, offset, searchTerm, selectedFilters } = this.state;
    let url = `/wl/marketing/getcontent?limit=${limit}&offset=${offset}`;
    if (searchTerm) {
      url = url + `&search=${encodeURIComponent(searchTerm)}`;
    }
    if (selectedFilters.length) {
      let filtertags = selectedFilters.map((fil) => {
        return fil._id;
      });
      url = url + `&tagsIdColl=${JSON.stringify(filtertags)}`;
    }
    axios({
      url: url,
      method: "GET",
      baseURL: "https://cmanager.classplus.co/",
      body: undefined,
      headers: {
        "Api-Version": 8,
        "x-access-token": this.props.token,
      },
    })
      .then((res) => {
        this.handleSuccess(res);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("ERROR", "Something went wrong");
      });
  };

  handleSuccess = (res) => {
    let { offset } = this.state;

    let resImages = res.data.data.content.contents;

    this.setState((prevState) => {
      let prevImages = [...prevState.images, ...resImages];
      return {
        images: offset == 0 ? resImages : prevImages,
        offset: prevState.offset + prevState.limit,
        totalCount: res.data.data.content.contentCount,
        noMore: res.data.data.content.contentCount == prevImages.length,
        loading: false,
      };
    });
  };

  fetchTags = () => {
    let { filterLimit, filterOffset } = this.state;
    this.setState({
      filterLoading: true,
    });
    axios({
      url: `wl/marketing/gettags?limit=${filterLimit}&offset=${filterOffset}`,
      method: "GET",
      baseURL: "https://cmanager.classplus.co/",
      body: undefined,
      headers: {
        "Api-Version": 8,
        "x-access-token": this.props.token,
      },
    })
      .then((res) => {
        this.setState((prevState) => {
          let responseTags = res.data.data.tags.map((tag) => {
            return {
              ...tag,
              isSelected: false,
            };
          });
          let filters = [...prevState.filtersResponse, ...responseTags];
          return {
            filtersResponse: filters,
            filterOffset: prevState.filterOffset + prevState.filterLimit,
            filterLoading: false,
            filterTotalCount: res.data.data.count,
            filterNoMore: res.data.data.count === filters.length,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        toastr.error("ERROR", "Something went wrong");
      });
  };

  handleSelect = (filter) => {
    let filters = [...this.state.filtersResponse];
    filters.forEach((fil) => {
      if (fil._id === filter._id) {
        fil.isSelected = !fil.isSelected;
      }
    });
    this.setState(
      {
        filtersResponse: filters,
      },
      () => {
        let filters = [...this.state.filtersResponse];
        let selectedFilters = filters.filter((fil) => {
          return fil.isSelected === true;
        });
        this.setState({
          selectedFilters,
        });
      }
    );
  };

  handleSelectImage = () => {
    let { selectedImage } = this.state;
    console.log("I am the selected Image", selectedImage);

    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.onDeeplinkExecuted) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_IMAGE_PICK,${selectedImage.title},${selectedImage.imageUrl}`
      );

    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_IMAGE_PICK,${selectedImage.title},${selectedImage.imageUrl}`
      );
    }

    
  };

  toggleImageSelection = (img) => {
    let selectedImage = { ...img };

    this.setState({
      selectedImage,
    });

    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.onDeeplinkExecuted) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_IMAGE_PICK,${selectedImage.title},${selectedImage.imageUrl}`
      );

    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_IMAGE_PICK,${selectedImage.title},${selectedImage.imageUrl}`
      );
    }
    
  };

  deselectImageSelection = (img) => {
    this.setState({ selectedImage: {} });
  };

  handleDownloadClick = () => {
    let selectedImage = { ...this.state.selectedImage };
    axios({
      url: "wl/marketing/download",
      method: "GET",
      baseURL: "https://cmanager.classplus.co/",
      body: undefined,
      headers: {
        "Api-Version": 8,
        "x-access-token": this.props.token,
      },
    })
      .then((res) => {
        window.open(selectedImage.imageUrl);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("ERROR", "Something went wrong");
      });
  };

  handleSearch = (term) => {
    if (this.searchingTimeout) {
      clearTimeout(this.searchingTimeout);
    }
    this.setState(
      {
        searchTerm: term,
        loading: true,
        offset: 0,
        images: [],
        searching: true,
        noMore: false,
        totalCount: 0,
      },
      () => {
        this.searchingTimeout = setTimeout(() => {
          // debouncing
          this.fetchImages();
        }, 500);
      }
    );
  };

  handleApplyClick = () => {
    this.setState(
      {
        images: [],
        offset: 0,
        selectedImage: {},
        loading: true,
        totalCount: 0,
        noMore: false,
      },
      () => {
        this.fetchImages();
        this.filterClicked();
      }
    );
  };

  handleClearAll = () => {
    let filtersResponse = [...this.state.filtersResponse];
    filtersResponse.forEach((fil) => {
      fil.isSelected = false;
    });
    this.setState(
      {
        filtersResponse,
        selectedFilters: [],
        images: [],
        offset: 0,
        selectedImage: {},
        loading: true,
        totalCount: 0,
        noMore: false,
      },
      () => {
        this.fetchImages();
        this.filterClicked();
      }
    );
  };

  handleFolderSelect = () => {
    console.log("inside handle folder select");
  };

  togglePreviewImage = () => {
    this.setState({
      showPreview: !this.state.showPreview,
    });
  };

  render() {
    const {
      isFilter,
      selectedImage,
      searchTerm,
      loading,
      images,
      filtersResponse,
      filterLoading,
      filterNoMore,
      showPreview,
    } = this.state;

    console.log(selectedImage, "selectedImage");
    console.log(loading, "loading");
    return !showPreview ? (
      <div className={classes.List}>
        <div className={classes.UpperDivision}>
          <Search handleSearch={this.handleSearch} searchTerm={searchTerm} />
          <div className={classes.Filter}>
            <button
              className={classes.FilterButton}
              onClick={this.filterClicked}
            >
              <img src={filter} alt="" />

              {this.state.selectedFilters.length ? (
                <div className={classes.filterThreeDot}></div>
              ) : (
                ""
              )}
            </button>
            {isFilter ? (
              <Filter
                handleSelect={this.handleSelect}
                filtersResponse={filtersResponse}
                filterNoMore={filterNoMore}
                filterLoading={filterLoading}
                fetchTags={this.fetchTags}
                handleApplyClick={this.handleApplyClick}
                handleClearAll={this.handleClearAll}
                filterClicked={this.filterClicked}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={classes.infoText}>
          Now you can select from a variety of readymade images to make your
          notifications more engaging.
        </div>

        <div className={classes.LowerDivision}>
          <div className={classes.CategoryList}>
            <InfiniteScroll
              next={() => this.fetchImages()}
              hasMore={false}
              height="100%"
              style={
                !images.length && !loading
                  ? infinteScrollEmptyState
                  : infiniteScrollStyle
              }
              loader={
                loading && (
                  <div className={classes.centeredDiv}>
                    <Loader inverted content="Loading..." />
                  </div>
                )
              }
              dataLength={images.length}
            >
              {images && images.length > 0 ? (
                images.map((img) => {
                  return (
                    <ImageCard
                      key={img._id}
                      imgData={img}
                      toggleImageSelection={this.toggleImageSelection}
                      togglePreviewImage={this.togglePreviewImage}
                    />
                  );
                })
              ) : !loading ? (
                <div className={classes.centeredDiv}>
                  <div className={classes.noImagesText}>No images found</div>
                </div>
              ) : (
                <div className={classes.loaderList}>Loading...</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.PreviewContainer}>
        <div className={classes.PreviewCard}>
          <ImagePreviewCard
            imgData={selectedImage}
            handleSelectImage={this.handleSelectImage}
            handleDownloadClick={this.handleDownloadClick}
            togglePreviewImage={this.togglePreviewImage}
            toggleImageSelection={this.toggleImageSelection}
            deselectImageSelection={this.deselectImageSelection}
          />
        </div>
      </div>
    );
  }
}
export default List;
