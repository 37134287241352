import React, { Component } from "react";
import classes from "./ImageCard.module.css";
import defaultCard from "../../images/defaultNotificationImage.png";

class ImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectImage: false,
      dynamicHeight: "90px",
    };
  }

  componentDidMount() {
    this.setState({ dynamicHeight: this.imageRef.clientWidth * 2 });
  }

  getTags = (tags) => {
    let tagString = "";
    if (tags) {
      tags.forEach((tag, index) => {
        tagString = tagString + tag.name;
        if (index < tags.length - 1) {
          tagString = tagString + ", ";
        }
      });
    }

    return tagString;
  };

  selectImage = (img) => {
    this.props.toggleImageSelection(img);
    // this.props.togglePreviewImage();
  };

  render() {
    let { imgData } = this.props;

    return (
      <div
        className={classes.CardItem}
        onClick={() => {
          this.selectImage(imgData);
        }}
      >
        <img
          src={imgData && imgData.imageUrl ? imgData.imageUrl : defaultCard}
          alt="NotificationImage"
          ref={(ele) => {
            this.imageRef = ele;
          }}
          style={{
            height: this.state.dynamicHeight,
            width: "100%",
            maxHeight: "100px",
            maxWidth: "200px",
          }}
        />
        <div className={classes.mDImageContain}>
          <div className={classes.mDImageHeading}>
            {imgData && imgData.title}
          </div>
          <div className={classes.mDImageCategory}>
            {this.getTags(imgData && imgData.tags)}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCard;
